const config = {
    api: {
        url: "http://localhost:3001"
    },
    google: {
        clientId: "592260160711-bd7gsbvjq2j6jft0eifed38i4rplpn4g.apps.googleusercontent.com",
    },
    stripe: {
        key: "pk_test_51JelKwH8hQfkHNdNHrFXPIo5ASzfiO5I8t5vDxQMatIzgGVd7SBiV4ZdD052VZKNd0JDVbcd6flaVHZbDMyEi6Vb00iJBqIB2o",
    }
}

export default config;
